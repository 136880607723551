import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiFilePdf, mdiFolder } from '@mdi/js';
import { Button, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/header';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { acadmicoFuente, academicoUpdatedAt, convenioAcademico } from '../../utils/transparencia-difusion/constants';
import { useStyles } from '../../utils/transparencia-difusion/styles';
import { useGlobalStyles } from '../../utils/styles';
import { escala_gris } from '../../utils/constants';
import { IListDocs } from '../../utils/transparencia-difusion/interfaces';
import { Home } from '@material-ui/icons';

const ColaboracionAcademico = () => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const [data, setData] = useState<IListDocs[]>(convenioAcademico);
  
    return (
      <Layout>
        <SEO title='Convenios de colaboración académica' />
  
        <div className={globalClasses.container}>
          <Header title='Convenios de colaboración académica' />
  
          <div className={globalClasses.content}>
            <List className={classes.list}>
              {!data[0].root ? (
                <Button
                  startIcon={<Home />}
                  variant="outlined"
                  color="primary"
                  onClick={() => setData(convenioAcademico)}
                  style={{ marginBottom: 20 }}>
                  Inicio
                </Button>
              ) : null}
  
              {data.map((document, index) => (
                document.elements ?
                  (
                    <ListItem key={index} button onClick={() => setData(document?.elements || [])}>
                      <ListItemAvatar>
                        <Icon path={mdiFolder} size={1.7} color={escala_gris ? '#767576' : '#fad390'} />
                      </ListItemAvatar>
                      <ListItemText primary={document.name} />
                    </ListItem>
                  ) : (
                    <a key={index} href={document.file} target="_blank" rel="noopener noreferrer">
                      <ListItem button>
                        <ListItemAvatar>
                          <Icon path={mdiFilePdf} size={1.7} color={escala_gris ? '#767576' : '#e54e5d'} />
                        </ListItemAvatar>
                        <ListItemText primary={document.name} secondary={document.size} />
                      </ListItem>
                    </a>
                  )
              ))}
            </List>
          </div>
  
          <UpdatedInfo fuente={acadmicoFuente} updatedAt={academicoUpdatedAt} />
        </div>
      </Layout>
    );
  };
  export default ColaboracionAcademico;